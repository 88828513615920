<template>
  <div class="content">
    <Header></Header>
    <div class="router-container">
      <router-view />
    </div>
    <a class="link-imprint" @click="$router.push('/imprint')">Imprint</a>
  </div>
</template>
<script>
import Header from './components/Header.vue';
export default {
  components: {
    Header
  }
}
</script>
<style src="@/styles/style.css"></style>
<style src="@/styles/loader.css"></style>
<style>
.content {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.router-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100;
}

.link-imprint {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  font-weight: bold;
  color: var(--blue);
  z-index: 999;
}

.link-imprint:hover {
  cursor: pointer;
}
</style>