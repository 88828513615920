<template>
    <div class="hero-background">
        <div class="hero-container">
            <div>
                <font-awesome-icon class="icon" :icon="['fas', 'bolt']" />
                <h1>Find EV Charging Stations with Amenities</h1>
                <p>Discover Convenient Electric Vehicle Charging Stations with Additional Amenities for a Seamless Charging
                    Experience.
                </p>
                <button @click="$router.push('/login')">Try Demo Now</button>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'HeroComponent'
}
</script>
<style>
.hero-background {
    flex: 1;
    background-image: linear-gradient(var(--green-dark-transparent), var(--blue-transparent)), url('@/assets/background.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
}

.hero-container {
    display: flex;
    flex-direction: column;
    width: 40%;
    align-items: center;
    margin: auto;
}

.hero-container h1 {
    font-size: 3rem;
    font-weight: 800;
    margin: 0;
    color: var(--blue);
}

.hero-container p {
    margin: 3rem 0;
    color: var(--white);
    font-size: 1.1875rem;
}

.hero-container button {
    padding: 1rem;
    background-color: var(--green-dark);
    color: var(--white);
    font-size: 1.25rem;
    font-weight: bold;
    border: 0;
    border-radius: 5px;
    transition: background-color 0.4s linear;
}

.hero-container button:hover {
    background-color: var(--blue);
    cursor: pointer;
}

.hero-container .icon {
    font-size: 5rem;
    margin: 0.5rem 0;
    color: var(--blue);
}

/*#region Media queries*/

/* Notebook */
@media (max-width: 1550px) {}

/* Tablet */
@media (max-width: 991px) {
    .hero-container {
        width: 80%;
    }

    .hero-container p {
        font-size: 1.5rem;
    }
}

/* Mobile */
@media (max-width: 767px) {
    .hero-container {
        width: 90%;
    }

    .hero-container h1 {
        font-size: 2.5rem;
    }

    .hero-container p {
        font-size: 1.25rem;
    }
}

/*#endregion*/
</style>