<template>
  <header class="nav">
    <div class="nav-content">
      <div class="logo">
        <a @click="$router.push('/map')"><img src="@/assets/logo/logo.svg" alt="" srcset=""></a>
      </div>
      <nav class="nav-links" v-if="!isLoggedIn">
        <a @click="$router.push('/register')">Sign up</a>
        <a class="nav-links-main" @click="$router.push('/login')">Login</a>
      </nav>
      <nav class="nav-links" v-else>
        <a class="nav-links-main" @click="signOut()">Logout</a>
      </nav>
    </div>
  </header>
  <div class="demo-banner">This is a Demo Application - All Information is Without Guarantee.</div>
</template>
<script>
import TokenStorageService from '@/services/TokenStorageService';
import { store } from '@/store';

export default {
  name: "HeaderComponent",
  computed: {
    isLoggedIn() {
      return store.isLoggedIn;
    },
  },
  methods: {
    signOut() {
      TokenStorageService.signOut();
      this.$router.push('/');
      store.isLoggedIn = false;
    }
  }
}
</script>
<style>
.nav {
  flex: 0;
  background-color: var(--blue);
  width: 100%;
  z-index: 1000;
  padding: 0.5rem 4rem;
}

.nav-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav-content .logo a:hover {
  cursor: pointer;
}

.nav-content p {
  font-size: 1.75rem;
  font-weight: bold;
  color: var(--white);
  letter-spacing: 1px;
  margin: 0;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.nav-links a {
  text-decoration: none;
  font-size: 1.125rem;
  font-weight: bold;
  color: var(--white);
  cursor: pointer;
  letter-spacing: 1px;
  transition: all 0.4s linear;
}

.nav-links a:hover {
  color: var(--green-dark);
}

.nav-links-main {
  background-color: var(--green-dark);
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.nav-links-main:hover {
  background-color: transparent;
}

.logo img {
  width: 7rem;
}

.demo-banner {
  background-color: var(--green-dark);
  color: var(--white);
  padding: 0.5rem;
  text-align: center;
  font-weight: bold;
  font-size: 0.75rem;
  letter-spacing: 1px;
  text-transform: uppercase;
}

/*#region Media queries*/

/* Notebook */
@media (max-width: 1550px) {}

/* Tablet */
@media (max-width: 991px) {
  .nav {
    padding: 0.5rem 2rem;
  }
}

/* Mobile */
@media (max-width: 767px) {
  .nav {
    padding: 0.5rem 1rem;
  }

  .demo-banner {
    letter-spacing: 0;
  }
}

/*#endregion*/
</style>