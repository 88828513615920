import { createRouter, createWebHistory } from 'vue-router';
import Hero from '@/components/Hero.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Hero
  },
  {
    path: '/map',
    name: 'map',
    meta: {
      requiresAuth: true
    },
    component: () => import('@/components/Map.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/components/Register.vue')
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: () => import('@/components/VerifyEmail.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/components/Login.vue')
  },
  {
    path: '/hero',
    name: 'hero',
    component: () => import('@/components/Hero.vue')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/components/ResetPassword.vue')
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import('@/components/ChangePassword.vue')
  },
  {
    path: '/imprint',
    name: 'imprint',
    component: () => import('@/components/Imprint.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {

  if (to.matched.some((record) => record.meta.requiresAuth)) {

    const token = window.sessionStorage.getItem('auth-token');

    if (!token) {
      next('/login');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router