const TOKEN_KEY = 'auth-token';

export default {
  signOut() {
    window.sessionStorage.clear();
  },

  saveToken(token) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  },

  getToken() {
    return window.sessionStorage.getItem(TOKEN_KEY);
  },

  isLoggedIn() {
    return this.getToken() != null;
  }
};